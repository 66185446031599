import {
  ActionButtonVariant,
  ButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import ConditionalWrapper from '@aurora/shared-client/components/common/ConditionalWrapper/ConditionalWrapper';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import MessageStatusLabel from '@aurora/shared-client/components/messages/MessageStatusLabel/MessageStatusLabel';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import UserRank from '@aurora/shared-client/components/users/UserRank/UserRank';
import Icons from '@aurora/shared-client/icons';
import type {
  Article,
  AttachmentEdge,
  AttachmentSearchEdge,
  IdeaTopicMessage,
  Message,
  OccasionTopicMessage,
  TkbTopicMessage,
  TopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  SearchSnippetKey
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { buildMenuItems } from '../../../../helpers/managecontent/ManageContentHelper';
import {
  isTeaserImagePresent,
  isTeaserVideoPresent
} from '../../../../helpers/messages/MessageHelper/MessageHelper';
import { getSearchSnippet } from '../../../../helpers/search/SearchSnippetHelper';
import type { ItemType } from '../../../../types/enums';
import { MessageViewVariant, ViewSize } from '../../../../types/enums';
import type {
  MessageContentWorkflowFragment,
  MessageTeaserFragment,
  MessageViewFragment,
  OccasionTimeFragment
} from '../../../../types/graphql-types';
import type { ItemViewVariantFC } from '../../../entities/types';
import KudosButton from '../../../kudos/KudosButton/KudosButton';
import KudosCount from '../../../kudos/KudosCount/KudosCount';
import OccasionProgressBadge from '../../../occasions/OccasionProgressBadge/OccasionProgressBadge';
import OccasionAttendeeCount from '../../../occasions/OccasionAttendeeCount/OccasionAttendeeCount';
import OccasionDateIcon from '../../../occasions/OccasionDateIcon/OccasionDateIcon';
import OccasionLocationData from '../../../occasions/OccasionLocationData/OccasionLocationData';
import OccasionRsvpIndicator from '../../../occasions/OccasionRsvpIndicator/OccasionRsvpIndicator';
import OccasionTime from '../../../occasions/OccasionTime/OccasionTime';
import OccasionType from '../../../occasions/OccasionType/OccasionType';
import TagList from '../../../tags/TagList/TagList';
import useInView from '../../../useInView';
import useTranslation from '../../../useTranslation';
import MessageActionMenu from '../../MessageActionMenu/MessageActionMenu';
import MessageBody from '../../MessageBody/MessageBody';
import MessageLink from '../../MessageLink/MessageLink';
import MessageModerator from '../../MessageModerator/MessageModerator';
import MessagePreviewImage from '../../MessagePreviewImage/MessagePreviewImage';
import MessagePreviewVideo from '../../MessagePreviewVideo/MessagePreviewVideo';
import MessagePublishStatus from '../../MessagePublishStatus/MessagePublishStatus';
import MessageReadOnlyIcon from '../../MessageReadOnlyIcon/MessageReadOnlyIcon';
import MessageRejectedStatus from '../../MessageRejectedStatus/MessageRejectedStatus';
import MessageRepliesCount from '../../MessageRepliesCount/MessageRepliesCount';
import MessageSolvedBadge from '../../MessageSolvedBadge/MessageSolvedBadge';
import MessageSubject from '../../MessageSubject/MessageSubject';
import type { ActionButtonComponentProps } from '../../MessageSubjectOverviewModal/MessageSubjectOverviewModal';
import MessageTeaser from '../../MessageTeaser/MessageTeaser';
import MessageTimeToRead from '../../MessageTimeToRead/MessageTimeToRead';
import MessageUnreadCount from '../../MessageUnreadCount/MessageUnreadCount';
import MessageViewCount from '../../MessageViewCount/MessageViewCount';
import MessageWorkflowState from '../../MessageWorkflowState/MessageWorkflowState';
import localStyles from './MessageViewInline.module.pcss';
import MessageViewInlineAuthorLogin from './MessageViewInlineAuthorLogin';
import MessageViewInlineNode from './MessageViewInlineNode';
import MessageViewInlineTimeWrapper from './MessageViewInlineTimeWrapper';
import {
  isOccasionImminentOrOngoing,
  occasionImminentOrOngoing
} from '../../../../helpers/util/OccasionTimeHelper';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import UserLink from '../../../users/UserLink/UserLink';

const MessageActionMarkAsApproved = dynamic(
  () => import('../../MessageActionMarkAsApproved/MessageActionMarkAsApproved'),
  { ssr: false }
);
const MessageActionMarkAsNotAbuse = dynamic(
  () => import('../../MessageActionMarkAsNotAbuse/MessageActionMarkAsNotAbuse'),
  { ssr: false }
);
const MessageActionMarkAsNotSpam = dynamic(
  () => import('../../MessageActionMarkAsNotSpam/MessageActionMarkAsNotSpam'),
  { ssr: false }
);
const MessageActionReject = dynamic(() => import('../../MessageActionReject/MessageActionReject'), {
  ssr: false
});
const MessageContentWorkflowActionMenu = dynamic(
  () => import('../../MessageContentWorkflowActionMenu/MessageContentWorkflowActionMenu'),
  {
    ssr: false
  }
);

/**
 * Message View - Inline
 *
 * @author Jonathan Bridges
 */
const MessageViewInline: ItemViewVariantFC<
  Message,
  ItemType.MESSAGE,
  MessageViewVariant.INLINE
> = props => {
  const {
    entity: message,
    className,
    useAvatar,
    useBoardIcon,
    boardIconSize,
    useSubject,
    useSubjectLink,
    useReadOnlyIcon,
    useSolvedBadge,
    subjectAs,
    viewSize,
    useCompactSpacing,
    subjectLinkClassName,
    clampSubjectLines,
    useBody,
    clampBodyLines,
    useSearchSnippet,
    usePreviewMedia,
    useAuthorLogin,
    useAuthorRank,
    useNode,
    useTags,
    useKudosCount,
    useRepliesCount,
    renderPostTimeBeforeAuthor,
    useViewCount,
    useUnreadCount,
    useTimeToRead,
    timeStampType,
    useAttachments,
    useTeaser,
    useWorkflowState,
    useRejectedStatus,
    useWorkflowActionMenu,
    useModerationActionMenu,
    usePreviewSubjectModal,
    useDraftView,
    boardIconClassName,
    subjectClassName,
    bodyClassName,
    footerClassName,
    searchData,
    usePublishStatus,
    useModerator,
    openLinksInNewTab,
    useVideoPreview,
    useOccasionTime,
    useOccasionLocation,
    useOccasionDateIcon,
    useVoteComponent,
    useAttendeeCount,
    useAttendanceIndicator,
    useMessageStatus,
    useMessageLink,
    useOccasionType,
    useOccasionBadge,
    useEntireItemAsLink,
    useManualSortNav,
    subFooter = null
  } = props;

  const tenant = useContext(TenantContext);
  const cx = useClassNameMapper(localStyles);
  const [inViewRef, inView] = useInView<HTMLDivElement>();
  const { loading: textLoading, intl } = useTranslation(EndUserComponent.MESSAGE_VIEW_INLINE);
  const { board } = message;
  const { teaser } = message as TkbTopicMessage;
  const clampSubjectClassName = clampSubjectLines
    ? `lia-g-clamp lia-g-clamp-${clampSubjectLines}`
    : '';
  const showTags =
    useTags &&
    tenant.publicConfig.labelsEnabled &&
    (message as TopicMessage).tags?.edges?.length > 0;
  const showMessageStatus =
    message.board.conversationStyle === ConversationStyle.Idea &&
    useMessageStatus &&
    (message as IdeaTopicMessage).status;
  const showTimeForReadingBlog =
    board?.conversationStyle === ConversationStyle.Blog && useTimeToRead;
  const { searchSnippet } = searchData ?? {};
  const bodySearchSnippet = getSearchSnippet(searchSnippet, SearchSnippetKey.Body);
  const subjectSearchSnippet = getSearchSnippet(searchSnippet, SearchSnippetKey.Subject);
  const showReadOnly = useReadOnlyIcon && message?.readOnly === true;
  const showSolvedBadge = useSolvedBadge && message?.conversation?.solved === true;
  const occasionData = (message as OccasionTopicMessage)?.occasionData;

  if (textLoading) {
    return null;
  }

  const showOccasionBadge = useOccasionBadge && isOccasionImminentOrOngoing(occasionData);

  const renderBoardIcon = (): React.ReactElement => {
    return (
      <ConditionalWrapper
        condition={useMessageLink && !useEntireItemAsLink}
        wrapper={({ children: messageChildren }) => (
          <MessageLink message={message} useManualSortNav={useManualSortNav}>
            {messageChildren}
          </MessageLink>
        )}
      >
        <NodeIcon
          size={boardIconSize}
          node={message.board}
          className={cx('lia-g-mt-5', boardIconClassName)}
        />
      </ConditionalWrapper>
    );
  };

  const renderOccasionDateIcon = (): ReactElement => {
    return (
      <OccasionDateIcon
        occasion={(message as OccasionTopicMessage)?.occasionData as OccasionTimeFragment}
      />
    );
  };

  /**
   * Renders the status of the message
   */
  function renderMessageStatus() {
    const messageStatus = (message as IdeaTopicMessage).status;
    return (
      showMessageStatus && (
        <div className={cx('lia-g-divider lia-byline-item')}>
          <MessageStatusLabel status={messageStatus} className={cx('lia-g-pl-0')} />
        </div>
      )
    );
  }

  const ActionButtonComponent: React.FC<React.PropsWithChildren<ActionButtonComponentProps>> = ({
    onCloseSubjectModal,
    onHideSubjectModal
  }) => {
    const {
      moderationData: { rejectReason }
    } = message as MessageViewFragment;
    if (!rejectReason) {
      return (
        <>
          <MessageActionMarkAsApproved
            message={message}
            actionButtonVariant={ActionButtonVariant.SUBJECT_MODAL_BUTTON}
            onCloseSubjectModal={onCloseSubjectModal}
            onHideSubjectModal={onHideSubjectModal}
          />
          <MessageActionReject
            message={message}
            actionButtonVariant={ActionButtonVariant.SUBJECT_MODAL_BUTTON}
            onCloseSubjectModal={onCloseSubjectModal}
            onHideSubjectModal={onHideSubjectModal}
          />
        </>
      );
    } else {
      switch (rejectReason) {
        case 'SPAM': {
          return (
            <MessageActionMarkAsNotSpam
              message={message}
              buttonVariant={ButtonVariant.PRIMARY}
              isModalAction
              isDashboardAction
              onCloseSubjectModal={onCloseSubjectModal}
              onHideSubjectModal={onHideSubjectModal}
            />
          );
        }
        case 'ABUSE': {
          return (
            <MessageActionMarkAsNotAbuse
              message={message}
              isModalAction
              onCloseSubjectModal={onCloseSubjectModal}
              onHideSubjectModal={onHideSubjectModal}
            />
          );
        }
        case 'OTHER': {
          return (
            <MessageActionMarkAsApproved
              message={message}
              actionButtonVariant={ActionButtonVariant.SUBJECT_MODAL_BUTTON}
              onCloseSubjectModal={onCloseSubjectModal}
              onHideSubjectModal={onHideSubjectModal}
            />
          );
        }
      }
    }
  };

  // Header Elements
  const renderSubject = (): ReactElement => {
    return (
      <MessageSubject
        message={message}
        as={subjectAs}
        subjectLinkClassName={cx('lia-subject-link', subjectLinkClassName, clampSubjectClassName)}
        className={cx(
          'lia-subject',
          { 'lia-subject-xs': viewSize === ViewSize.XS },
          subjectClassName
        )}
        useLink={useSubjectLink && !useEntireItemAsLink}
        useTitle
        isDraftView={useDraftView}
        subjectSearchSnippet={subjectSearchSnippet}
        usePreviewSubjectModal={usePreviewSubjectModal}
        actionButtonComponent={ActionButtonComponent}
        useNewTab={openLinksInNewTab}
        useSearchSnippet={useSearchSnippet}
        useManualSortNav={useManualSortNav}
      />
    );
  };

  const renderSolvedBadge = (): ReactElement => {
    return <MessageSolvedBadge message={message} />;
  };

  const renderReadOnlyIcon = (): ReactElement => {
    return <MessageReadOnlyIcon message={message} />;
  };

  const renderAuthorInfo = (): ReactElement => {
    return (
      <div className={cx('lia-g-divider lia-author-info')}>
        {useAuthorLogin && (
          <MessageViewInlineAuthorLogin
            message={message}
            useUserHoverCard={props.useUserHoverCard}
            useAuthorLoginLink={props.useAuthorLoginLink && !useEntireItemAsLink}
            useContributors={props.useContributors}
            className={cx('lia-byline-item')}
          />
        )}
        {useAuthorRank && <UserRank user={message.author} />}
      </div>
    );
  };

  // Body
  const renderBody = (): ReactElement => {
    return (
      <MessageBody
        trimBody
        as="span"
        className={cx(bodyClassName, `lia-body lia-body-${viewSize}`)}
        message={message}
        bodySearchSnippet={bodySearchSnippet}
        clampLines={clampBodyLines}
        useSearchSnippet={useSearchSnippet}
      />
    );
  };

  // Teaser
  const renderTeaser = (): ReactElement => {
    return (
      <MessageTeaser
        as="span"
        className={cx(`lia-body lia-body-${viewSize}`, {
          [`lia-g-clamp lia-g-clamp-${clampBodyLines}`]: clampBodyLines
        })}
        teaserContent={message as MessageTeaserFragment}
      />
    );
  };

  // If teaser is present and useTeaser is true render teaser otherwise render body
  const renderPreviewText = (): ReactElement => {
    return useTeaser && teaser && teaser.trim() ? renderTeaser() : renderBody();
  };

  // Preview Image
  const renderPreviewImage = (): ReactElement => {
    return <MessagePreviewImage className={cx('lia-preview-img')} message={message} />;
  };

  /**
   * renders the preview thumbnail for the video
   */
  const renderPreviewVideoThumbnail = (): ReactElement => {
    return <MessagePreviewVideo message={message} variant={MessageViewVariant.INLINE} />;
  };

  /**
   * renders the appropriate media (image/video) preview
   */
  const renderMediaPreview = (): ReactElement => {
    if (useVideoPreview && isTeaserVideoPresent(message)) {
      return renderPreviewVideoThumbnail();
    }

    if (isTeaserImagePresent(message)) {
      return renderPreviewImage();
    }

    return useVideoPreview && message?.videos?.totalCount > 0
      ? renderPreviewVideoThumbnail()
      : renderPreviewImage();
  };

  const renderOccasionLocation = (): ReactElement => {
    return (
      <OccasionLocationData
        className={cx('text-break lia-byline-item')}
        occasion={message as OccasionTopicMessage}
      />
    );
  };

  const renderOccasionType = (): ReactElement => {
    return (
      <OccasionType occasionType={(message as OccasionTopicMessage)?.occasionData?.occasionType} />
    );
  };

  const renderOccasionTime = (): ReactElement => {
    return (
      <OccasionTime
        occasion={(message as OccasionTopicMessage)?.occasionData as OccasionTimeFragment}
        className={cx(`lia-byline-item lia-byline-${viewSize}`)}
      />
    );
  };

  const renderOccasionRsvpIndicator = (): ReactElement => {
    return (
      <OccasionRsvpIndicator
        message={message}
        className={cx(`font-weight-bold lia-byline-item lia-byline-${viewSize}`)}
      />
    );
  };

  const renderUnreadCount = (): ReactElement => {
    return <MessageUnreadCount message={message} className={cx('lia-g-divider lia-byline-item')} />;
  };

  // Other Footer Elements
  const renderTags = (): ReactElement => {
    return (message as TopicMessage).tags?.edges?.length > 0 || inView ? (
      <TagList message={message} />
    ) : null;
  };

  const renderViewsCount = (): ReactElement => {
    return <MessageViewCount message={message} />;
  };

  const renderKudosCount = (): ReactElement => {
    return <KudosCount message={message} />;
  };

  const renderVoteCount = (): ReactElement => {
    return (
      <KudosButton
        message={message}
        view={MessageViewVariant.INLINE}
        kudo={message.hasGivenKudo}
        kudosSum={message.kudosSumWeight}
        revisionNum={message.revisionNum}
        messageType={(message as MessageViewFragment).__typename}
      />
    );
  };

  const renderMessageRepliesCount = (): ReactElement => {
    return <MessageRepliesCount message={message} />;
  };

  const renderOccasionAttendeeCount = (): ReactElement => {
    return <OccasionAttendeeCount message={message} />;
  };

  const renderOccasionProgressBadge = (): ReactElement => {
    return (
      <OccasionProgressBadge occasionData={occasionData} statuses={occasionImminentOrOngoing} />
    );
  };

  const renderMessageWorkflowState = (): ReactElement => {
    return <MessageWorkflowState message={message as MessageContentWorkflowFragment} />;
  };

  const renderMessageRejectedStatus = (): ReactElement => {
    return <MessageRejectedStatus message={message} />;
  };

  const renderMessageWorkflowActionMenu = (): ReactElement => {
    return <MessageContentWorkflowActionMenu message={message as Article} />;
  };

  const renderMessageModerationActionMenu = (): ReactElement => {
    return (
      <MessageActionMenu
        message={message}
        items={buildMenuItems(message?.moderationData?.rejectReason)}
        isDashboardAction
      />
    );
  };

  const renderTimeToRead = (): ReactElement => {
    return (
      <MessageTimeToRead
        as="span"
        className={cx('lia-g-subheading lia-subtitle')}
        message={message}
      />
    );
  };

  const renderPublishStatus = (): ReactElement => {
    return (
      <MessagePublishStatus message={message} className={cx('lia-g-divider lia-byline-item')} />
    );
  };

  const renderModerator = (): ReactElement => (
    <MessageModerator message={message} className={cx('lia-g-divider lia-byline-item')} />
  );

  /**
   * Renders the attachments
   */
  const renderAttachments = (): ReactElement => {
    let attachmentList = [];
    if (searchData?.attachments) {
      const { edges } = searchData?.attachments;
      attachmentList = edges?.map((attachment: AttachmentSearchEdge) => {
        const [snippet] = attachment.node.snippet;
        return snippet ? (
          <span
            className={cx('lia-attachment-name')}
            key={attachment.node.id}
            dangerouslySetInnerHTML={{ __html: snippet?.content[0] }}
          />
        ) : (
          <span className={cx('lia-attachment-name')}>{attachment.node.attachment.filename}</span>
        );
      });
      return (
        attachmentList?.length > 0 && (
          <div className={cx('lia-attachment')}>
            <Icon
              icon={Icons.AttachmentIcon}
              size={IconSize.PX_16}
              className={cx('lia-attachment-icon')}
            />
            <div>{intl.formatList(attachmentList, { type: 'unit' })}</div>
          </div>
        )
      );
    } else {
      const edges = message?.attachments?.edges;
      attachmentList = edges?.map((attachment: AttachmentEdge) => attachment.node.filename);
      return (
        attachmentList?.length > 0 && (
          <div className={cx('lia-attachment')}>
            <Icon
              icon={Icons.AttachmentIcon}
              size={IconSize.PX_16}
              className={cx('lia-attachment-icon')}
            />
            <span className={cx('lia-attachment-name')}>
              {intl.formatList(attachmentList, { type: 'unit' })}
            </span>
          </div>
        )
      );
    }
  };

  const useLeftSide = useAvatar || useBoardIcon || useOccasionDateIcon || useVoteComponent;
  const useLeftSideVoteOnly =
    !useAvatar && !useBoardIcon && !useOccasionDateIcon && useVoteComponent;
  const useBodyContent = showTimeForReadingBlog || useBody || usePreviewMedia;
  const useMeta = showSolvedBadge || showReadOnly || showOccasionBadge || useVoteComponent;
  const useMetaVoteOnly =
    !showSolvedBadge && !showReadOnly && !showOccasionBadge && useVoteComponent;
  const useMain = useBodyContent || useSubject || useMeta || showOccasionBadge;
  const useByline =
    useAuthorLogin ||
    useAuthorRank ||
    useNode ||
    timeStampType ||
    useUnreadCount ||
    usePublishStatus;
  const useLowerByline = showMessageStatus || showTags || useOccasionType;
  const useAuthorInfo = useAuthorLogin || useAuthorRank;

  const useFooter =
    useByline ||
    useOccasionTime ||
    showTags ||
    useKudosCount ||
    useRepliesCount ||
    useViewCount ||
    useAttendanceIndicator;
  const useRightFooter = useKudosCount || useRepliesCount || useViewCount || useAttendeeCount;
  const useSection = useMeta || useMain || useFooter || useAttachments;
  const useActionMenu = useWorkflowActionMenu || useModerationActionMenu;
  const useMainCompact = useCompactSpacing || (!useBodyContent && !useMeta);

  const contents = (
    <div
      className={cx('lia-message', !useEntireItemAsLink && className)}
      data-testid="InlineMessageView"
      ref={inViewRef}
    >
      {useLeftSide && (
        <div className={cx('lia-left-side', { 'lia-has-vote-only': useLeftSideVoteOnly })}>
          {useAvatar && !useEntireItemAsLink && (
            <UserLink
              user={message.author}
              className={cx('lia-g-mt-5')}
              useHoverCard={props.useUserHoverCard}
            >
              <UserAvatar user={message.author} size={props.avatarSize} />
            </UserLink>
          )}
          {useAvatar && useEntireItemAsLink && (
            <UserAvatar
              user={message.author}
              size={props.avatarSize}
              className={cx('lia-g-mt-5')}
            />
          )}
          {useBoardIcon && renderBoardIcon()}
          {useOccasionDateIcon && renderOccasionDateIcon()}
          {useVoteComponent && <div className={cx('d-lg-none d-flex')}>{renderVoteCount()}</div>}
        </div>
      )}
      {useSection && (
        <section className={cx('lia-section')}>
          {useMain && (
            <div
              className={cx(
                'lia-main',
                { 'lia-with-vote': useVoteComponent },
                { 'lia-with-meta': useMeta },
                { 'lia-meta-only-vote': useMetaVoteOnly },
                { 'lia-main-compact': useMainCompact }
              )}
            >
              {useSubject && renderSubject()}
              {useBodyContent && !useEntireItemAsLink && (
                <MessageLink
                  message={message}
                  className={cx('lia-content')}
                  tabIndex={-1}
                  useManualSortNav={useManualSortNav}
                >
                  {showTimeForReadingBlog && renderTimeToRead()}
                  {useBody && renderPreviewText()}
                  {usePreviewMedia && renderMediaPreview()}
                </MessageLink>
              )}
              {useBodyContent && useEntireItemAsLink && (
                <div className={cx('lia-content')}>
                  {showTimeForReadingBlog && renderTimeToRead()}
                  {useBody && renderPreviewText()}
                  {usePreviewMedia && renderMediaPreview()}
                </div>
              )}
              {useMeta && (
                <div className={cx('lia-meta')}>
                  {useVoteComponent && (
                    <div className={cx('d-none d-lg-block')}>{renderVoteCount()}</div>
                  )}
                  {showSolvedBadge && renderSolvedBadge()}
                  {showOccasionBadge && renderOccasionProgressBadge()}
                  {showReadOnly && renderReadOnlyIcon()}
                </div>
              )}
            </div>
          )}
          {useAttachments && renderAttachments()}
          {useFooter && (
            <div className={cx(footerClassName, 'lia-footer')}>
              {(useByline ||
                showTags ||
                useOccasionTime ||
                useAttendanceIndicator ||
                useOccasionType) && (
                <div className={cx('lia-footer-section lia-footer-left')}>
                  {useByline && (
                    <div className={cx(`lia-byline lia-byline-${viewSize}`)}>
                      {!renderPostTimeBeforeAuthor && useAuthorInfo && renderAuthorInfo()}
                      {timeStampType && (
                        <MessageViewInlineTimeWrapper
                          message={message}
                          timeStampType={props.timeStampType}
                          useMessageTimeLink={props.useMessageTimeLink && !useEntireItemAsLink}
                          useLastUpdateDateText={props.useLastUpdateDateText}
                        />
                      )}
                      {useOccasionLocation && renderOccasionLocation()}
                      {renderPostTimeBeforeAuthor && useAuthorInfo && renderAuthorInfo()}
                      {useNode && (
                        <MessageViewInlineNode
                          message={message}
                          useNodeLink={props.useNodeLink && !useEntireItemAsLink}
                          useNodeHoverCard={props.useNodeHoverCard}
                          useBoardIcon={props.useBoardIcon}
                          useShortTitle={props.useShortTitle}
                        />
                      )}
                      {useUnreadCount && renderUnreadCount()}
                      {usePublishStatus && renderPublishStatus()}
                      {useModerator && renderModerator()}
                    </div>
                  )}
                  {useOccasionTime && renderOccasionTime()}
                  {useAttendanceIndicator && renderOccasionRsvpIndicator()}
                  {useLowerByline && (
                    <div className={cx(`lia-byline lia-byline-${viewSize}`)}>
                      {useOccasionType && renderOccasionType()}
                      {showMessageStatus && renderMessageStatus()}
                      {showTags && renderTags()}
                    </div>
                  )}
                </div>
              )}
              {useRightFooter && (
                <div className={cx('lia-footer-section lia-footer-right')}>
                  {useViewCount && renderViewsCount()}
                  {useKudosCount && renderKudosCount()}
                  {useAttendeeCount && renderOccasionAttendeeCount()}
                  {useRepliesCount && renderMessageRepliesCount()}
                </div>
              )}
            </div>
          )}
          {subFooter && <div className={cx('d-flex lia-g-mt-10')}>{subFooter}</div>}
          {useWorkflowState && renderMessageWorkflowState()}
          {useRejectedStatus && renderMessageRejectedStatus()}
        </section>
      )}
      {useActionMenu && (
        <div className={cx('lia-g-ml-10')}>
          {useModerationActionMenu && renderMessageModerationActionMenu()}
          {useWorkflowActionMenu && renderMessageWorkflowActionMenu()}
        </div>
      )}
    </div>
  );

  return useEntireItemAsLink ? (
    <MessageLink
      message={message}
      className={cx(className, 'text-decoration-none')}
      useManualSortNav={useManualSortNav}
    >
      {contents}
    </MessageLink>
  ) : (
    contents
  );
};

export default MessageViewInline;
