import type {
  SearchSnippet,
  SearchSnippetKey
} from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Looks for a snippet string in a given set of snippets
 * @param snippets The list of snippets to search
 * @param key The SearchSnippetKey to look for
 * @returns The content of the found snippet, or null
 */
export function getSearchSnippet(snippets: SearchSnippet[], key: SearchSnippetKey): string {
  return snippets && snippets.find(snippet => snippet.key === key)?.content[0];
}
