import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import NodeLink from '../../../nodes/NodeLink/NodeLink';
import useTranslation from '../../../useTranslation';
import localStyles from './MessageViewInline.module.pcss';
import MessageViewInlineNodeBoard from './MessageViewInlineNodeBoard';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';

type Props = {
  message: Message;
  useNodeHoverCard: boolean;
  useNodeLink: boolean;
  useBoardIcon: boolean;
  useShortTitle: boolean;
};

/**
 * Renders the board related to the message, possibly as a link, for a MessageViewInline
 * Intended to be used directly by MessageViewInline
 * @author Rosalyn Rowe
 */
const MessageViewInlineNode: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  useNodeHoverCard,
  useNodeLink,
  useBoardIcon,
  useShortTitle
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_VIEW_INLINE);

  const className = cx('lia-g-divider lia-byline-item lia-node');

  return (
    <>
      {useNodeLink ? (
        <NodeLink
          node={message.board}
          className={className}
          useHoverCard={useNodeHoverCard}
          ariaLabel={formatMessage('gotoParent', { name: message.board.title })}
        >
          <MessageViewInlineNodeBoard
            message={message}
            useBoardIcon={useBoardIcon}
            useShortTitle={useShortTitle}
          />
        </NodeLink>
      ) : (
        <span className={className}>
          <MessageViewInlineNodeBoard
            message={message}
            useBoardIcon={useBoardIcon}
            useShortTitle={useShortTitle}
          />
        </span>
      )}
    </>
  );
};

export default MessageViewInlineNode;
