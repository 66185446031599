import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { MessageReadOnlyIconFragment } from '../../../types/graphql-types';

interface Props {
  /**
   * The message to display the read only icon for.
   */
  message: MessageReadOnlyIconFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Message read only icon.
 *
 * @author Jonathan Bridges
 */
const MessageReadOnlyIcon: React.FC<React.PropsWithChildren<Props>> = ({ message, className }) => {
  const cx = useClassNameMapper();
  return message?.readOnly ? (
    <Icon
      icon={Icons.LockIcon}
      size={IconSize.PX_14}
      color={IconColor.GRAY_600}
      className={cx(className)}
      testId="messageReadOnlyIcon"
    />
  ) : null;
};

export default MessageReadOnlyIcon;
