import React from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import type { MessageModerationDataFragment } from '../../../types/graphql-types';
import localStyles from '../MessageWorkflowState/MessageWorkflowState.module.pcss';

interface Props {
  /**
   * The message to display the rejected status for
   */
  message: MessageModerationDataFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: String;
}

/**
 * Message Rejected Status
 * @author Arjun Krishna
 */
const MessageRejectedStatus: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  return (
    <Badge variant="light" className={cx(className, 'lia-state-wrapper')}>
      <span className={cx('lia-message-state-text')}>{message?.moderationData?.rejectReason}</span>
    </Badge>
  );
};

export default MessageRejectedStatus;
