import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { MessageUnreadCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The message to display the unread count for.
   */
  message: MessageUnreadCountFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
}

/**
 * Message Unread reply count
 * @author Aditi Agarwal
 */
const MessageUnreadCount: React.FC<React.PropsWithChildren<Props>> = ({
  as: Component = 'span',
  message,
  className
}) => {
  const cx = useClassNameMapper();
  const {
    formatMessageAsNode,
    FormattedMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.MESSAGE_UNREAD_COUNT);

  if (textLoading) {
    return null;
  }

  return message?.conversation?.unreadReplyCount ? (
    <Component className={cx(className)} data-testid="UnreadCount">
      <span className={cx('sr-only h-auto')}>
        <FormattedMessage
          id="comments"
          values={{
            count: message.conversation.unreadReplyCount,
            spanText: function renderChunks(chunks): React.ReactNode {
              return <span>{chunks}</span>;
            },
            spanCount: function renderCount(): React.ReactNode {
              return <span>{message.conversation.unreadReplyCount}</span>;
            }
          }}
        />
      </span>
      <span aria-hidden="true">
        {formatMessageAsNode('unread', { count: message.conversation.unreadReplyCount })}
      </span>
    </Component>
  ) : null;
};

export default MessageUnreadCount;
