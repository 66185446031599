import type { Message, TkbTopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../../useTranslation';
import MessageContributors from '../../MessageContributors/MessageContributors';
import UserLink from '../../../users/UserLink/UserLink';

interface Props {
  /**
   * Class name(s) to apply to the element.
   */
  className?: string;
  message: Message;
  useAuthorLoginLink: boolean;
  useUserHoverCard: boolean;
  useContributors: boolean;
}

/**
 * Renders the username of the author, possibly as a link, for a MessageViewInline
 * May also render other contributors.
 * @author Rosalyn Rowe
 */
const MessageViewInlineAuthorLogin: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  useAuthorLoginLink,
  useUserHoverCard,
  useContributors
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_VIEW_INLINE
  );

  if (textLoading) {
    return null;
  }

  const bylineAuthor = message?.author?.login ?? formatMessage('anonymous');
  const formattedBylineAuthor = formatMessage('bylineAuthor', { bylineAuthor });

  return (
    <>
      {useAuthorLoginLink ? (
        <UserLink user={message.author} className={cx(className)} useHoverCard={useUserHoverCard}>
          {formattedBylineAuthor}
        </UserLink>
      ) : (
        <span className={cx(className)}>{formattedBylineAuthor}</span>
      )}
      {useContributors && (
        <MessageContributors message={message as TkbTopicMessage} className={cx(className)} />
      )}
    </>
  );
};

export default MessageViewInlineAuthorLogin;
