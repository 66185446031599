import useDateTime from '@aurora/shared-client/components/useDateTime';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import type { OccasionTimeFragment } from '../../../types/graphql-types';
import localStyles from './OccasionDateIcon.module.pcss';
import usePreferredTimezone from '@aurora/shared-client/components/usePreferredTimezone';

interface Props {
  /**
   * The occasion to display the date icon for.
   */
  occasion: OccasionTimeFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Display icon for occasion date.
 * @author Aditi Agarwal
 */
const OccasionDateIcon: React.FC<React.PropsWithChildren<Props>> = ({ occasion, className }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatAbsoluteDate } = useDateTime();
  const { startTime } = occasion || {};
  const { formatDate } = useIntl();

  const { data: timezoneData, loading: timezoneLoading } = usePreferredTimezone();

  if (timezoneLoading) {
    return null;
  }

  const userPreferredTimeZone = timezoneData?.self.profileSettings.timeZone?.value;

  const occasionStartTime = formatAbsoluteDate(startTime, {
    timeZone: userPreferredTimeZone,
    hour: 'numeric',
    minute: 'numeric'
  });

  const occasionStartMonth = new Date(
    formatDate(startTime, {
      timeZone: userPreferredTimeZone
    })
  );

  return occasionStartTime ? (
    <div className={cx(className, 'lia-date-icon')} data-testid="OccasionDateIcon">
      <div className={cx('lia-month')}>{occasionStartTime.slice(0, 3)}</div>
      <div className={cx('lia-date')}>{occasionStartMonth.getDate()}</div>
    </div>
  ) : null;
};

export default OccasionDateIcon;
