import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../../useTranslation';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';

type Props = {
  message: Message;
  useBoardIcon: boolean;
  useShortTitle: boolean;
};

/**
 * Renders information related to the message's board for a MessageViewInline
 * Intended to be used by MessageViewInlineNode
 * @author Rosalyn Rowe
 */
const MessageViewInlineNodeBoard: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  useBoardIcon,
  useShortTitle
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_VIEW_INLINE
  );
  const cx = useClassNameMapper();

  if (textLoading) {
    return null;
  }

  const bylineBoard =
    useShortTitle && message?.board?.shortTitle ? message.board.shortTitle : message?.board?.title;

  return (
    <>
      {!useBoardIcon && <NodeIcon size={IconSize.PX_12} node={message.board} />}
      <span className={cx('sr-only h-auto')}>{formatMessage('place', { bylineBoard })}</span>
      <span aria-hidden="true">{formatMessage('bylineBoard', { bylineBoard })}</span>
    </>
  );
};

export default MessageViewInlineNodeBoard;
