import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import MessageTime from '../../MessageTime/MessageTime';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';
import type { MessageTimestamp } from '../../../../types/enums';

interface Props {
  /** Class name(s) to apply to the MessageTime component */
  className?: string;

  message: Message;
  timeStampType: MessageTimestamp;
  useLastUpdateDateText: boolean;
}

/**
 * Wrapper for MessageTime specifically for MessageViewInlineTime
 * Intended to be used by MessageViewInlineTimeWrapper
 * @author Rosalyn Rowe
 */
const MessageViewInlineTime: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  timeStampType,
  useLastUpdateDateText
}) => {
  const cx = useClassNameMapper();

  return (
    <MessageTime
      message={message}
      className={cx(className)}
      timestampType={timeStampType}
      useLastUpdateDateText={useLastUpdateDateText}
      usePreferredDateDisplayStyle
    />
  );
};

export default MessageViewInlineTime;
