import useDateTime from '@aurora/shared-client/components/useDateTime';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getPropertyByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { MessageTimestamp } from '../../../../types/enums';
import MessageLink from '../../MessageLink/MessageLink';
import { MessageListMenuItem } from '../../MessageListMenu/MessageListMenu';
import localStyles from './MessageViewInline.module.pcss';
import MessageViewInlineTime from './MessageViewInlineTime';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';

type Props = {
  message: Message;
  timeStampType: MessageTimestamp;
  useMessageTimeLink: boolean;
  useLastUpdateDateText: boolean;
};

/**
 * Conditionally renders the MessageTime for a MessageViewCard, possibly with a link
 * Intended to be used directly by MessageViewCard
 * @author Rosalyn Rowe
 */
const MessageViewInlineTimeWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  timeStampType,
  useMessageTimeLink,
  useLastUpdateDateText
}) => {
  const cx = useClassNameMapper(localStyles);

  const item = MessageListMenuItem.POST_TIME_DESC;
  const queryParam =
    timeStampType === MessageTimestamp.LAST_POSTING_ACTIVITY_TIME &&
    message?.lastPublishTime != message?.conversation?.lastPostingActivityTime
      ? { topicRepliesSort: item, [EndUserQueryParams.AUTO_SCROLL]: 'true' }
      : null;
  const { formatRelativeTime } = useDateTime();
  const className = cx('lia-g-divider lia-byline-item');
  const time = getPropertyByPath(message, timeStampType);

  return useMessageTimeLink ? (
    <MessageLink
      message={message}
      query={queryParam}
      className={className}
      ariaLabel={formatRelativeTime(time)}
    >
      <MessageViewInlineTime
        message={message}
        useLastUpdateDateText={useLastUpdateDateText}
        timeStampType={timeStampType}
      />
    </MessageLink>
  ) : (
    <MessageViewInlineTime
      className={className}
      message={message}
      useLastUpdateDateText={useLastUpdateDateText}
      timeStampType={timeStampType}
    />
  );
};

export default MessageViewInlineTimeWrapper;
